<template>

  <swiper
    :modules="modules"
    :slides-per-view="1"
    :space-between="50"
    navigation
    :pagination="{ clickable: true }"
    :scrollbar="{ draggable: false }"
    @swiper="onSwiper"
    @slideChange="onSlideChange"
  >
    <swiper-slide class="slider">
      <div class="image_cont">
        <img src="../assets/newprod.jpeg" />
      </div>
      <p>Stone-coated Sheet</p>
    </swiper-slide>
    <swiper-slide class="slider">
      <div class="image_cont">
        <img src="../assets/newprod2.jpeg" />
      </div>
      <p>Aluminum Roofing Sheet</p>
    </swiper-slide>
    <swiper-slide class="slider">
      <div class="image_cont">
        <img src="../assets/newprod3.jpeg" />
      </div>
      <p>Stone-Coated Roof</p>
    </swiper-slide>
   
    <swiper-slide class="slider">
      <div class="image_cont">
        <img src="../assets/newprod4.jpeg" />
      </div>
      <p>Polycarbonate Roofing</p>
    </swiper-slide>
    <swiper-slide class="slider">
      <div class="image_cont">
        <img src="../assets/sliderimage4.png" />
      </div>
      <p>General Service</p>
    </swiper-slide>
    <swiper-slide class="slider">
      <div class="image_cont">
        <img src="../assets/sliderimage1.png" />
      </div>
      <p>Stone-Coated Roof</p>
    </swiper-slide>
    ........
  </swiper>
</template>
<script>
// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
// import 'swiper/css/scrollbar';

// Import Swiper styles
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const onSwiper = (swiper) => {
     // console.log(swiper);
    };
    const onSlideChange = () => {
    //  console.log("slide change");
    };
    return {
      onSwiper,
      onSlideChange,
      modules: [Navigation, Pagination, Scrollbar, A11y],
    };
  },
};
</script>


<style scoped>
.slider {
  background: #f0f8f3;
  border-radius: 8px;
  height: 300px;
  margin-bottom: 50px;

}

.image_cont {
  width: 100%;
  height: 250px;
  text-align: center;
}
.image_cont img {
  width: inherit;
  height: inherit;
} 

.slider p {
  font-weight: bold;
  font-size: 20px;
  margin: 13px;
  text-align: center;
}

@media only screen and (max-width: 600px) {
  .slider{
    width: 100%;
  }
}
</style>