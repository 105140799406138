<template>
  <div class="scroll fixed-bottom" @click="openModal()">
    <span><i class="fa fa-message"></i> </span>
  </div>

  <!-- The Modal -->
  <div class="modal" id="myModal" v-if="modal == true">
    <!-- Modal content -->
    <div class="modal-content">
      <span class="close" @click="closeModal()"> &times;</span>
      <form>
        <div class="formgroup">
          <label>Customer Name</label>
          <input
            type="text"
            placeholder=" Full Name"
            class="form-control"
            v-model="name"
          />
        </div>
        <div class="formgroup">
          <label>Email Address</label>
          <input
            type="email"
            placeholder="Email Address"
            class="form-control"
            v-model="email"
          />
        </div>

        <div class="formgroup">
          <label>Request</label>
          <textarea
            name=""
            id=""
            cols="20"
            rows="5"
            v-model="message"
            class="form-control"
          ></textarea>
        </div>
        <div class="formgroup upload-btn-wrapper">
          <label>Upload Product Image</label><br />
          <input
            type="file"
            multiple
            @change="onFileChange"
            id="input-file-now"
            class="file-upload"
          />
          <div v-if="prod_image" class="image_tab">
            <div
              class="image_tab_img"
              v-for="(image, index) in prod_image"
              :key="image.id"
            >
              <img :src="image" />
              <button @click="removeImage(index)">Remove image</button>
            </div>
          </div>
        </div>

        <button class="schedule" @click.prevent="sendMessage()">
          Send Order
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import axios from 'axios';
export default {
  data() {
    return {
      modal: false,
      prod_image: [],
      name: "",
      email: "",
      message: "",
      
    };
  },

  methods: {
    closeModal() {
      // Get the modal
      var modal = document.getElementById("myModal");
      modal.style.display = "none";
    },
    openModal() {
      if (this.modal == "") {
        return (this.modal = true);
      }
      return (this.modal = "");
    },

    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files);
    },
    createImage(files) {
      var vm = this;
      for (var index = 0; index < files.length; index++) {
        var reader = new FileReader();
        reader.onload = function (event) {
          const imageUrl = event.target.result;
          vm.prod_image.push(imageUrl);
        };
        reader.readAsDataURL(files[index]);
      }
    },
    removeImage(index) {
      this.prod_image.splice(index, 1);
    },

    sendMessage() {
      if (this.name == "" || this.email == "" || this.message == "") {
        Swal.fire({
          title: "Error!",
          text: "All Field Must Be Filled",
          icon: "error",
        });
        return false;
      } else {
        var params = new FormData();
        params.append("name", this.name);
        params.append("email", this.email);
        params.append("message", this.message);
        params.append("prod_image", this.prod_image);
        //   alert(params)
        axios
          .post("https://diplotech.ng/mail/send_email.php", params, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            Swal.fire({
              title: "Success!",
              text: "Message Submitted!!!, We will get back to you.",
              icon: "success",
            });
           location.reload()
          })
          .catch((error) => {
          //  console.error(error);
          });
      }
    },
  },
};
</script>

<style scoped>
.scroll {
  display: block;
  width: 150px;
  background: #359100;
  border-radius: 35px;
  padding: 25px 2px;
  cursor: pointer;
  color: aliceblue;
  text-align: center;
  left: 3%;
  bottom: 30px;
}

/* The Modal (background) */
.modal {
  display: block;
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(176, 34, 34); /* Fallback color */
  background-color: rgba(1, 1, 1, 0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 10% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 50%;
  border-radius: 8px;
  height: auto;
}
.modal-content h5 {
  text-align: center;
  color: black;
  font-family: sans-serif;
  font-weight: bold;
}
/* The Close Button */
.close {
  color: red;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
.file-upload-wrapper {
  width: 50%;
  margin: auto;
}

.schedule {
  display: block;
  width: 40%;
  margin: auto;
  background: green;
  padding: 10px;
  border-radius: 10px;
}
.formgroup {
  margin: 20px;
}
.formgroup label {
  color: black;
}
.formgroup input {
  box-shadow: none;
}
form button {
  border: none;
  font-weight: bold;
}

.formgroup textarea {
  resize: none;
  box-shadow: none;
}
.image_tab {
  height: 150px;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
}
.image_tab_img {
  width: 30%;
  height: 100px;
  margin: 15px 5px;
}
.image_tab_img img {
  width: 100%;
  height: 100px;
  display: block;
}
.image_tab_img button {
  display: block;
  width: 80%;
  border-radius: 5px;
  margin: 8px auto;
  font-size: 15px;
}

@media only screen and (max-width: 600px) {
  .scroll {
    left: 230px;
  }

  .modal {
    overflow: hidden;
  }
  /* Modal Content/Box */
  .modal-content {
    background-color: #fefefe;
    margin: 20% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    border-radius: 8px;
    min-height: 40%;
  }
  .modal-content h5 {
    text-align: center;
    color: black;
    font-weight: bold;
  }
  /* The Close Button */
  .close {
    color: red;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }

  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  .file-upload-wrapper {
    width: 50%;
    margin: auto;
  }

  .schedule {
    display: block;
    width: 40%;
    margin: auto;
    background: green;
    padding: 10px;
  }
  .formgroup {
    margin: 20px;
  }
  .formgroup label {
    color: black;
  }

  .formgroup textarea {
    resize: none;
  }

  @media only screen and (max-width: 600px) {
    .modal-content {
      background-color: #fefefe;
      margin: 30% auto;
      padding: 20px;
      border: 1px solid #888;
      width: 90%;
      border-radius: 8px;
      height: 80%;
      overflow: scroll;
    }

    .image_tab_img {
      width: 30%;
      height: auto;
      margin: 15px 5px;
    }
  }
}
</style>