<template>
  <router-view />
  <div class="container">
    <div class="row">
      <div class="col-sm-11 mx-auto">
        <h5><scroll /></h5>
      </div>
    </div>
  </div>
</template>
<script>
import scroll from "@/components/scrollup.vue";
export default {
  components: {
    scroll,
  },
};
</script>

<style>
body {
  padding: 0px !important;
  margin: 0px !important;
  font-family: "Inter" !important;
  scrollbar-width: none !important;
  box-sizing: border-box !important;
  -ms-overflow-style: none !important; /* for Internet Explorer, Edge */
}
html {
  scrollbar-width: none;
}

body::-webkit-scrollbar {
  display: none;
}

* {
  padding: 0px;
  margin: 0px;
}
a{
  text-decoration: none !important;;
}

</style>
