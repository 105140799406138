<template>
  <footer>
    <div>
      <h4>Quick Links</h4>
      <ul>
        <li><router-link to="/">Home</router-link></li>
        <li><router-link to="/about">About Us</router-link></li>
        <li><router-link to="/product">Services</router-link></li>
        <li><router-link to="/project">Projects</router-link></li>
      </ul>
    </div>
    <div>
      <h4>Come find us</h4>
      <ul>
        <li>Building address :</li>
        <li>
          No 6 Soderu Street, Off Orita Challenge Road <br> - Odo Ona Elewe, Ibadan
        </li>
        <li>Email Address :</li>
        <li>
         info@diplotech.com
        </li>
        <li>Phone number :</li>
        <li>
         +234 806 063 1166
        </li>
      </ul>
    </div>
    <div class="policies">
      <h4>Policies</h4>
      <ul>
        <li>Privacy Policy</li>
        <li>Terms of service</li>
        <li>Shipping Policy</li>
        <div class="social_media">
          <a href="https://m.facebook.com/100076272076679/"><li><i class="fab fa-facebook fa-3x facebook"></i></li></a>
          <a href="https://instagram.com/diplotechforte?igshid=YmMyMTA2M2Y="><li><i class="fab fa-instagram fa-3x ml-3"></i></li></a>
          <a href="https://api.whatsapp.com/send?phone=+2348060631166"><li><i class="fab fa-whatsapp fa-3x ml-4"></i></li></a>
   
        </div>
      </ul>
    </div>
  </footer>
</template>


<style scoped>
/* s */
footer {
  display: flex;
  margin-top: 30px;
}
footer > div {
  width: 100%;
}
footer ul,
h4 {
  list-style: none;
  font-size: 15px;
  color: white;
}
footer li{
  margin: 10px 0px;
}
.social_media {
  display: flex;
}
.footer h4{
  font-weight: bold;
}
.social_media a{
  text-decoration: none;
  color: white;
 
}
a, a:hover{
   text-decoration: none !important;
  color: white;

}
/* .policies{
  margin-left: 10rem;
} */

@media only screen and (max-width: 600px) {
  footer{
    flex-direction: column;
  }
 
.facebook{
  margin-left: 100px;
}
}
</style>