<template>
  <Navbar />
  <div class="container-fluid home">
    <div class="row">
      <div class="col-sm-11 mx-auto mt-4">
        <div class="row">
          <div class="col-sm-6 col-12 order-2 home_carousel">
            <div
              id="carouselExampleIndicators"
              class="carousel slide carousel-fade"
              data-ride="carousel"
            >
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <div class="headline">
                    <h5 class="active_prop">Intergrity</h5>
                    <h5 class="ml-0 ml-lg-5">Partnership</h5>
                    <h5 class="ml-0 ml-lg-5">Expertise</h5>
                  </div>
                  <p>
                    Diplotech is guided by strong executive leadership with a
                    major emphasis on consistent quality as a key operational
                    objective, supported by an efficient operational and
                    administrative supply system.
                  </p>
                  <router-link to="/product"> <button>Our Products</button></router-link>
                 
                </div>
                <div class="carousel-item">
                  <div class="headline">
                    <h5>Intergrity</h5>
                    <h5 class="active_prop ml-0 ml-lg-5">Partnership</h5>
                    <h5 class="ml-0 ml-lg-5">Expertise</h5>
                  </div>
                  <p>
                    Diplotech is guided by strong executive leadership with a
                    major emphasis on consistent quality as a key operational
                    objective, supported by an efficient operational and
                    administrative supply system.
                  </p>
                  <router-link to="/project" > <button>Our Projects</button></router-link>
                 
                </div>
                <div class="carousel-item">
                  <div class="headline">
                    <h5>Intergrity</h5>
                    <h5 class="ml-0 ml-lg-5">Partnership</h5>
                    <h5 class="active_prop ml-0 ml-lg-5">Expertise</h5>
                  </div>
                  <p>
                    Diplotech is guided by strong executive leadership with a
                    major emphasis on consistent quality as a key operational
                    objective, supported by an efficient operational and
                    administrative supply system.
                  </p>
                 <router-link to="/product"><button>Our Services</button></router-link> 
                </div>
              </div>
              <a
                class="carousel-control-prev"
                href="#carouselExampleIndicators"
                role="button"
                data-slide="prev"
              >
              </a>
              <a
                class="carousel-control-next"
                href="#carouselExampleIndicators"
                role="button"
                data-slide="next"
              >
              </a>
            </div>
          </div>
          <div class="col-sm-6 col-12 order-1 order-sm-2 home_carousel">
            <div
              id="carouselExampleIndicators"
              class="carousel slide carousel-fade"
              data-ride="carousel"
            >
              <ol class="carousel-indicators">
                <li
                  data-target="#carouselExampleIndicators"
                  data-slide-to="0"
                  class="active"
                ></li>
                <li
                  data-target="#carouselExampleIndicators"
                  data-slide-to="1"
                ></li>
                <li
                  data-target="#carouselExampleIndicators"
                  data-slide-to="2"
                ></li>
                  <li
                  data-target="#carouselExampleIndicators"
                  data-slide-to="3"
                ></li>

                  <li
                  data-target="#carouselExampleIndicators"
                  data-slide-to="4"
                ></li>
              </ol>
              <div class="carousel-inner">
                <div class="carousel-item active" >
                  <div class="imagetab" >
                    <img
                      class="w-100"
                      src="../assets/interior.jpeg"
                      alt="First slide"
                    />
                  </div>
                </div>
                <div class="carousel-item">
                  <div class="imagetab" >
                    <img
                      class="w-100"
                      src="../assets/gen.jpeg"
                      alt="Second slide"
                    />
                  </div>
                </div>
                <div class="carousel-item">
                  <div class="imagetab"  >
                    <img
                      class="w-100"
                      src="../assets/rebuild.jpeg"
                      alt="Third slide"
                    />
                  </div>
                </div>

                <div class="carousel-item">
                  <div class="imagetab" >
                    <img
                      class="w-100"
                      src="../assets/mbuild.jpeg"
                      alt="Second slide"
                    />
                  </div>
                </div>

                <div class="carousel-item">
                  <div class="imagetab" >
                    <img
                      class="w-100"
                      src="../assets/ext.jpeg"
                      alt="Second slide"
                    />
                  </div>
                </div>

                
              </div>
              <a
                class="carousel-control-prev"
                href="#carouselExampleIndicators"
                role="button"
                data-slide="prev"
              >
              </a>
              <a
                class="carousel-control-next"
                href="#carouselExampleIndicators"
                role="button"
                data-slide="next"
              >
              </a>
            </div>
          </div>

          <div class="col-sm-8 col-12 mx-auto mt-5 who_are_we order-3">
            <h5>Who We Are</h5>
            <div class="who_we_are_det">
              <span>
                <p>
                  Diplotech Forte Services is a pre-eminent focused home and
                  construction service that offers varieties of services from
                  Real Estate, Landscaping, Structural Design, and Architecture
                  to Sales of properties and construction supplies. <br />
                </p>

                <p>
                  Over the years, the company has taken on many challenging
                  projects and has accumulated skills and experiences in home
                  designs, building construction, real estate, Roof/PVC
                  installations, and other related engineering work.
                </p>

                <p>
                  Today, Diplotech Forte Service takes on the role of the main
                  contractor for small to medium size projects providing quality
                  solutions and incredible results on projects we carry out.
                </p>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid divisions">
    <div class="row">
      <div class="col-sm-11 mx-auto">
        <div class="row">
          <div class="col-sm-8 mx-auto">
            <div class="top_division mt-3">
              <h5>Our Divisions</h5>
              <p>Our company operates under two separate arms.</p>
            </div>
          </div>
          <div class="col-sm-12 mt-5">
            <div class="row">
              <div class="col-sm-5 division_det order-2">
                <h5>Diplotech Forte Service</h5>
                <span>
                  <p style="margin-top: 30px">
                    <span style="font-weight: bold"
                      >Diplotech Forte Service</span
                    >
                    is the arm of our company that deals with the supplies and
                    sales of construction materials countrywide.
                  </p>

                  <p>
                    This arm of our company provides and supplies roofing
                    installations, roof water drainage systems, state of art
                    furniture designs, railings/gallery window installations,
                    fiberboards, and a wide range of construction supplies for
                    general contractors and building owners.
                  </p>

                  <p>
                    We take on the role of main building supply for small to
                    large size construction projects. Our objective is to
                    provide our clients with quality in our supplies and
                    long-term partnership on supplies we are chosen to deliver.
                  </p>
                </span>
                <router-link to="/product">   <button>Our Products</button></router-link>
             
              </div>
              <div class="col-sm-7 division_det_img order-1 order-sm-2">
                <img src="../assets/divisionimg.png" />
              </div>
            </div>

            <div class="row mt-5">
              <div class="col-sm-6 division_det_img">
                <img src="../assets/division_img.png" />
              </div>

              <div class="col-sm-6 division_det">
                <h5>Diplotech Home and Construction</h5>
                <span>
                  <p style="margin-top: 30px">
                    <span style="font-weight: bold">
                      Diplotech Home and Construction
                    </span>
                    is the arm of our company that deals with the constructions
                    and management of properties.
                  </p>

                  <p>
                    This arm of our company offers Real Estate management,
                    Architectural Solutions, Quantity Surveying and Landscaping,
                    Structural Design, Selling of Properties, and a wide range
                    of related property management solutions.
                  </p>

                  <p>
                    Our team proactively works diligently to provide quality
                    construction and management service to clients we work with.
                    We have years of experience up our sleeves and are very
                    certain to deliver the best.
                  </p>
                </span>
                <router-link to="/product"> <button>Our Products</button></router-link>
               
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-11 mx-auto product_page mt-5">
        <h5>Our Products</h5>
        <div class="row">
          <div class="col-sm-12 d-none d-lg-block">
            <Swiper />
            <router-link to="/product"> <button>View Products</button></router-link>
           
          </div>
          <div class="col-sm-12 d-block d-sm-none">
            <mobileSwiper />
            <router-link to="/product"> <button>View Products</button></router-link>
           
          </div>
        </div>
      </div>

      <div class="col-sm-11 mx-auto product_page mt-5">
        <h5>Our Services</h5>
        <div class="row">
          <div class="col-sm-12 d-none d-lg-block">
            <Swiper_Services />
            <router-link to="/product">
            <button>View Services</button></router-link>
          </div>

          <div class="col-sm-12 d-block d-sm-none">
            <SwiperMobileServices />
            <router-link to="/product"> <button>View Products</button></router-link>
           
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid divisions mt-4">
    <div class="row">
      <div class="col-sm-5 mx-auto mt-5 contact_form">
        <h5>Contact Us</h5>

        <form>
          <div class="formgroup">
            <label>Name</label>
            <input type="text" class="form-control" v-model="name" />
          </div>

          <div class="formgroup">
            <label>Email</label>
            <input type="text" class="form-control" v-model="email" />
          </div>

          <div class="formgroup">
            <label>Message</label>
            <textarea
              name=""
              id=""
              cols="20"
              rows="10"
              class="form-control" v-model="message"
            ></textarea>
          </div>

          <button @click.prevent="sendMessage()">Send Messages</button>
        </form>
      </div>

      <div class="col-sm-11 mx-auto">
        <h5><scroll /></h5>
      </div>
    </div>
  </div>

  <div class="container-fluid footer">
    <div class="row">
      <div class="col-sm-11 mx-auto">
        <h3>Diplotech Forte Services</h3>
        <Footer />
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 botom_footer">
        <h3>&copy 2022 Copyright: Diplotech Forte Services</h3>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Navbar from "@/components/navbar.vue";
import Swiper from "@/components/swiper.vue";
import Swiper_Services from "@/components/servicesswiper.vue";
import mobileSwiper from "@/components/mobileswiper.vue";
import SwiperMobileServices from "@/components/swipermobileServices.vue";
import Footer from "@/components/footer.vue";
import scroll from "@/components/scrollup.vue";
import Swal from 'sweetalert2'
import axios from 'axios'

export default {
  components: {
    Navbar,
    Swiper,
    Swiper_Services,
    Footer,
    mobileSwiper,
    SwiperMobileServices,
    scroll,

  },
  data(){
    return{
      name: "",
      email: "",
      message: ""

    }
  },
  methods: {
    sendMessage() {
      if (this.name == "" || this.email == "" || this.message == "") {
        Swal.fire({
          title: "Error!",
          text: "All Field Must Be Filled",
          icon: "error",
        });
        return false;
      } else {
        var params = new FormData();
        params.append("name", this.name);
        params.append("email", this.email);
        params.append("message", this.message);
        //   alert(params)
        axios
          .post("https://diplotech.ng/mail/send_email.php", params, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            Swal.fire({
              title: "Success!",
              text: "Message Submitted!!!, We will get back to you.",
              icon: "success",
            });
            location.reload()
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },
  },
};
</script>

<style scoped>
.home {
  padding-top: 3rem;
}
.headline {
  margin-top: 3rem;
}
.headline h5 {
  display: inline-block;
  margin: 2rem 0px;
  font-weight: bold;
  padding: 10px 0px;
}
.imagetab{
  height: 450px;
}
.home_carousel {
  z-index: 0;
}

.carousel .carousel-indicators li {
   width: 10px;
  height: 10px;
  border-radius: 100%;
 
}
.carousel-indicators li {
   color: green;
}


.active_prop {
  border-bottom: 4px solid #359100;
}
.product_link a {
  background: #359100;
  padding: 40px 20px;
}
.carousel-item button {
  background: #359100;
  margin-top: 15px;
  padding: 10px 30px;
  border-radius: 15px;
  outline: none;
  border: none;
  color: white;
}

/* .who_are_we{
  background: url(../assets/group.png);
  background-repeat: repeat-y;
  background-position: right;
}  */

.who_are_we h5 {
  font-size: 50px;
  font-weight: bold;
  text-align: center;
}
.who_we_are_det {
  height: auto;
  width: 80%;
  margin: auto;
  background: white;
}
.who_we_are_det p {
  padding: 10px 40px;
}
.divisions {
  background: #f0f8f3;
}

.top_division {
  text-align: center;
}
.top_division h5 {
  font-size: 40px;
  font-weight: bold;
}
.division_det h5 {
  font-weight: bold;
  font-size: 32px;
}
.division_det p {
  font-size: 15px;
}
.division_det_img {
  display: flex;
  align-items: center;
}
.division_det_img img {
  width: 100%;
}
.division_det button {
  background: #359100;
  outline: none;
  border: none;
  color: white;
  margin: 20px 0px;
  border-radius: 8px;
  padding: 10px 20px;
}
/*  <<<<<<<<<<<<<<<<<<<<<< Slider_Carosel >>>>>>>>>>>>>>>>>>>>>>>> */

.product_page h5 {
  font-size: 40px;
  font-weight: bold;
  text-align: center;
  padding-bottom: 50px;
}

.product_page button {
  background: #359100;
  display: block;
  width: 15%;
  margin: auto;
  margin-top: 20px;
  padding: 10px 30px;
  border-radius: 8px;
  outline: none;
  border: none;
  color: white;
}

.product_page a{
  text-decoration: none;
}
/* >>>>>>>>> Contact Form >>>>>>>>>>>> */

.contact_form h5 {
  text-align: center;
  font-weight: bold;
  font-size: 25px;
}
.contact_form form {
  margin-top: 15px;
  padding-bottom: 60px;
  box-shadow: none;
}

.contact_form input:focus,
textarea:focus {
  box-shadow: none;
}
.contact_form textarea {
  height: 150px;
  resize: none;
}
.contact_form button {
  background: #359100;
  display: block;
  outline: none;
  border: none;
  color: white;
  margin: 20px auto;
  border-radius: 8px;
  padding: 10px 20px;
}
/* >>>>>>>>> Contact Form Ends Here >>>>>>>>>>>> */

/* >>>>>>>>> Footer Starts Here Form >>>>>>>>>>>> */

.footer {
  background: #359100;
  color: white;
}
.footer h3 {
  margin-top: 20px;
  font-weight: bold;
}

.botom_footer {
  margin: 0px 0px;
  text-align: center;
  border-top: 2px solid grey;
  color: white;
}
.botom_footer h3 {
  font-size: 13px;
  padding: 10px;
}
@media only screen and (max-width: 600px) {
    .headline h5 {
    display: inline-block;
    margin: 2rem 10px;
    font-weight: bold;
    padding: 10px 7px;
  }
}
@media (max-width: 600px) {
  .headline {
    margin-top: 0rem;
  }
  .headline h5 {
    display: inline-block;
    margin: 2rem 0px;
    font-weight: bold;
    padding: 10px 7px;
  }
  .carousel-item button {
    display: block;
    margin: auto;
    margin-top: 30px;
  }
  .carousel-item p {
    text-align: center;
  }
  .imagetab{
  height: 300px;
}
  .division_det {
    text-align: center;
  }

  .who_we_are_det {
    height: auto;
    width: 100%;
    margin: auto;
    background: white;
  }

  .product_page button {
    width: 50%;
  }

  .footer h3 {
    text-align: center;
  }
}
</style>
