<template>

  <swiper class="swiper"
    :modules="modules"
    :slides-per-view="4"
    :space-between="50"
    navigation
    :pagination="{ clickable: true }"
    :scrollbar="{ draggable: false }"
    @swiper="onSwiper"
    @slideChange="onSlideChange"
  >
    <swiper-slide class="slider">
      <div class="image_cont">
        <img src="../assets/roof.jpeg" />
      </div>
      <p>Roofing</p>
    </swiper-slide>
    <swiper-slide class="slider">
      <div class="image_cont">
        <img src="../assets/ext.jpeg" />
      </div>
      <p>Exterior Decoration</p>
    </swiper-slide>
    <swiper-slide class="slider">
      <div class="image_cont">
        <img src="../assets/interior.jpeg" />
      </div>
      <p>Interior Design</p>
    </swiper-slide>
    <swiper-slide class="slider">
      <div class="image_cont">
        <img src="../assets/mbuild.jpeg" />
      </div>
      <p>General Construction</p>
    </swiper-slide>
    <swiper-slide class="slider">
      <div class="image_cont">
        <img src="../assets/gate.jpeg" />
      </div>
      <p>Gate Fixing</p>
    </swiper-slide>
    <swiper-slide class="slider">
      <div class="image_cont">
        <img src="../assets/rebuild.jpeg" />
      </div>
      <p>Rebuilding & Upgrading</p>
    </swiper-slide>
    <swiper-slide class="slider">
      <div class="image_cont">
        <img src="../assets/tiling.jpeg" />
      </div>
      <p>Tiling</p>
    </swiper-slide>

    ........
  </swiper>
</template>
<script>
// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
// import 'swiper/css/scrollbar';

// Import Swiper styles
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const onSwiper = (swiper) => {
    // console.log(swiper);
    };
    const onSlideChange = () => {
    //  console.log("slide change");
    };
    return {
      onSwiper,
      onSlideChange,
      modules: [Navigation, Pagination, Scrollbar, A11y],
    };
  },
};
</script>


<style scoped>
.swiper{
  z-index: 0;
}
.slider {
  background: #f0f8f3;
  border-radius: 8px;
  height: 300px;
  margin-bottom: 50px;
 
}

.image_cont {
  width: 100%;
  height: 250px;
  text-align: center;
}
.image_cont img {
  width: inherit;
  height: inherit;
} 

.slider p {
  font-weight: bold;
  font-size: 20px;
  margin: 13px;
  text-align: center;
}

@media only screen and (max-width: 600px) {
  .slider{
    width: 100%;
  }
}
</style>