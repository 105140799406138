<template>
  <div class="container-fluid navcontainer">
    <div class="row">
      <div class="col-sm-12 bg-white mt-2 home">
        <div class="row">
          <div class="col-sm-11 mx-auto navbar">
            <div>
              <router-link to="/">
                <img src="../assets/complogo.png"
              /></router-link>
            </div>
            <div>
              <ul>
                <router-link to="/">
                  <li>Home</li>
                </router-link>
                <router-link to="/about"> <li>About Us</li></router-link>
                <router-link to="/product">
                  <li>Products/Services</li></router-link
                >

                <router-link to="/project"> <li>Projects</li></router-link>
              </ul>
            </div>
            <div>
              <router-link to="/contact">
                <button>Contact Us</button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="mobile_nav">
    <div class="brand" v-if="mobile_menu == true">
      <i class="fa fa-bars fa-2x m-2" @click="open_nav"></i>
      <router-link to="/">
        <img src="../assets/complogo.png" alt="company logo" />
      </router-link>
    </div>

    <div class="brand2" v-if="mobile_menu2 == true">
      <router-link to="/"  @click="open_nav">
        <img src="../assets/complogo.png" alt="company logo" />
      </router-link>
    </div>

    <div class="mobile_detials" v-if="openNav != ''" @click="open_nav">
      <div class="nav_page">
        <ul>
          <router-link to="/" @click="open_nav">
            <li>Home</li>
          </router-link>
          <router-link to="/about" @click="open_nav">
            <li>About Us</li>
          </router-link>
          <router-link to="/product" @click="open_nav">
            <li>Product/Services</li>
          </router-link>

          <router-link to="/project" @click="open_nav">
            <li>Our Projects</li>
          </router-link>
          <router-link to="/contact" @click="open_nav">
            <li>Contact Us</li>
          </router-link>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      openNav: false,
      nav: true,
      mobile_menu: true,
      mobile_menu2: false,
    };
  },
  methods: {
    open_nav() {
      if (this.openNav == "") {
        this.mobile_menu = false;
        this.mobile_menu2 = true;
        return (this.openNav = true);
      }
      
        this.mobile_menu = true;
        this.mobile_menu2 = false;
      return (this.openNav = "");
    },
  },
};
</script>

<style scoped>
.navcontainer {
  background: #359100;
  height: 80px;
  position: fixed;
  z-index: 1;
}
.home {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.navbar {
  display: flex;
  align-items: center;
}
.navbar ul {
  list-style: none;
  display: flex;
}
.navbar li {
  margin: 0px 15px;
}

.navbar button {
  background: #359100;
  border-radius: 8px;
  outline: none;
  border: none;
  padding: 10px 20px;
  color: white;
}
.navbar a {
  text-decoration: none;
  color: black;
}

 .navbar a:hover,
 .navbar a.router-link-active,
 .navbar a.router-link-exact-active {
  color: #359100;
   cursor: pointer;
 }


@media only screen and (max-width: 600px) {
  .navcontainer {
    display: none;
  }
  .mobile_nav {
    display: block;
    position: fixed;
    background: white;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 10;
  }
  .brand {
    display: flex;
    align-items: center;
    padding: 5px;
  }
  .brand img {
    margin-left: 6.5rem;
  }
  .brand2 {
    display: block;
    align-items: center;
    padding: 5px;
  }
  .brand2 img {
    margin-left: 3.5rem !important;
  }

  .mobile_detials {
    width: 100%;
    position: absolute;
    opacity: 10;
    right: 0;
    left: 0;
    height: 100vh;
    background: white;
    z-index: 1;
  }

  .nav_page {
    background: #359100;
    width: 60%;
    height: 100vh;
    z-index: 1;
  }
  .nav_page ul {
    list-style: none;
    padding: 30px;
  }
  .nav_page li {
    margin: 25px 0px;
  }
  .nav_page a {
    color: white;
    text-decoration: none;
    font-weight: 300;
  }
}
</style>